((kv) => {


kv.loadEmojis = function() {

   var xobj = new XMLHttpRequest();
   xobj.overrideMimeType("application/json");
   xobj.open('GET', 'https://api.kiloverse.io/emoji.json', true); // Replace 'my_data' with the path to your file
   xobj.onreadystatechange = function () {
         if (xobj.readyState == 4 && xobj.status == "200") {
           // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
           kv.emojis = JSON.parse(xobj.responseText);
         }
   };
   xobj.send(null);

}

/*
const inst = kv.lookupInstrument(arg);
if(inst) {
  inst.prompt = arg;
  console.log('...found an  instrument for ' + arg);
  kv.ctx.instrument = inst;
  continue;
}


const bhv = kv.lookupMusicalBehavior(arg);
if(bhv){
  kv.ctx.behavior = bhv;
  console.log('...looked up behavior ' + arg);
  continue;
}
}

kv.getDefaultInstrumentBehavior = function(inst) {

  if(inst.type=='lead')return 'melody';
  if(inst.type=='bass')return 'bassline';
  return inst.type;

}
*/
kv.getRandomInstrumentForBehavior = function(behavior) {

  var type = behavior;
  if(behavior == 'melody')type='lead';
  if(behavior == 'bassline')type='bass';
  const r = kv.publicItems.filter((i)=>i.type == type);
  console.log('...getting random instrument for type ' + type);
  return r[Math.floor(r.length * Math.random())];

}

kv.lookupPattern = function(word) {

  if(['edm', 'techno', 'house'].includes(word)) {
  return {
      kick:[{t:0},{t:1},{t:2},{t:3},{t:4},{t:5},{t:6},{t:7},{t:7.5}],
      snare:[{t:1},{t:3},{t:5},{t:7}],
      hat:[{t:0.5},{t:1.5},{t:2.5},{t:3.5},{t:4.5},{t:5.5},{t:6.5},{t:7.5}],
      length:8}
    }

  return null;

}

kv.lookupTargetTrack = function(word) {


  const found = kv.items.filter((t)=>{

    if(t.visual?.prompt == word)return true;
    if(t.instrument?.prompt == word)return true;
    if(t.behavior && t.behavior == kv.lookupMusicalBehavior(word))return true;
    //if(t.item?.name.includes(word))return true;
  //  if(t.item?.description?.includes(word))return true;

    return false;

  });

  if(found.length>0)return found[0];
  return null;
}

kv.lookupMusicalBehavior = function(word){

  if(['bass','bassline'].includes(word))return 'bassline';
  if(['melody', 'lead'].includes(word))return 'melody';
  if(['beat', 'drumloop', 'rhythm'].includes(word))return 'kick'
  if(['kick', 'bassdrum', 'drum'].includes(word))return 'kick';
  if(['snare'].includes(word))return 'snare';
  if(['hat', 'hihat', 'hi-hat'].includes(word))return 'hat';

}

kv.lookupScale = function(word) {
  if(['major','happy', 'uplifting', 'nice'].includes(word)){
    return [0,2,4,5,7,9,11];
  }
  if(['sad', 'depressing', 'angry'].includes(word)){
    return [0,2,3,5,3];
  }
  return null;
}





kv.lookupInstrument = function(word) {
  const a =  kv.publicItems.filter((i)=> {return i.type?.includes(word) || i.name?.includes(word) || i.description?.includes(word)});
  if(a.length>0)return a[Math.floor(a.length * Math.random())];
}


kv.equals = (a, b, debug) => {
  if(debug){
    debugger;
  }
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date)
      return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')){
        console.log('a === b' + a===b);
        return a === b;
    }

    if (a.prototype !== b.prototype) {if(debug)debugger;return false};
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) {if(debug)debugger;return false};

    return keys.every(k => {
      if(typeof a !== 'object'){
        if(debug)debugger;
      }

      return kv.equals(a[k], b[k], debug);
    });
  };

kv.sampler = {};



kv.getTrackById = function(id) {
  return kv.items.filter((t)=>t.id == id)[0];
}

kv.getTrackByName = function(name) {
  return kv.items.filter((t)=>t.name == name)[0];
}

kv.getTrackByBehavior = function(type) {
  return kv.items.filter((t)=>t.behavior == type)[0];
}

kv.isInstrument = function(item) {
  return ['kick', 'snare', 'hat','lead','bass','nature','stab'].includes(item.type);
}

kv.isDrumType = function(type) {

  return ['kick', 'snare', 'hat'].includes(type);

}




kv.samplers = [];
kv.parts = [];

kv.l2l = function(l) {

  if(l<.25){
    return "16n";
  }else if(l <= .5){
    return "8n";
  }else if(l < 1.0){
    return "4n";
  }else if(l == 1.0){
    return "4n";
  }else if(l <= 2.0) {
    return "2n"
  }else if(l <= 4.0) {
    return "1m";
  }else if(l <= 8.0) {
    return "2m";
  }else{
  return "4n";
 }
  //n.l || "4n"
}

kv.b2b = function(b) {
  return Math.floor(b/4)+':'+Math.floor(b%4)+':'+((b%1.0)/.25);
}





kv.testContinue = function() {

  const args = ['the', 'kick']

  for(const arg of args){

    var txt = [...args].splice(args.indexOf(arg)+1, args.length).join(' ');

      if(kv.isUseless(arg))continue;

      console.log('processing kick because it is not useless');

  }

}


kv.defaultPattern =      {
        kick:[{t:0},{t:1},{t:2},{t:3},{t:4},{t:5},{t:6},{t:7},{t:7.5}],
        snare:[{t:1},{t:3},{t:5},{t:7}],
        hat:[{t:0.5},{t:1.5},{t:2.5},{t:3.5},{t:4.5},{t:5.5},{t:6.5},{t:7.5}],
        length:8
      }

kv.fileNameFromUrl = function(url) {
  return url.substring(url.lastIndexOf('/')+1);
}

kv.baseFromUrl = function(url) {
  return url.substring(0, url.lastIndexOf('/')+1);
}

kv.processMusicCommand = function(ctx) {

  if(!kv.ctx.visual && kv.ctx.instrument)kv.ctx.visual = {prompt:'light', sticker:'glow'};


  if(!kv.ctx.scale){
    kv.ctx.scale = [0,2,4,5,7,9,11];
    console.log('...using a defaut scale');
  }

  if(!kv.ctx.instrument && kv.ctx.behavior){
    kv.ctx.instrument = kv.getRandomInstrumentForBehavior(kv.ctx.behavior);
  }

  //replace the kick... etc...
  if(!kv.ctx.behavior && !kv.ctx.instrument && kv.ctx.target && kv.ctx.target?.instrument){
    Object.assign(kv.ctx.target?.instrument, kv.getRandomInstrumentForBehavior(kv.ctx.target?.instrument.type));
  }

  if(kv.ctx.instrument && !kv.ctx.behavior){
    kv.ctx.behavior = kv.getDefaultInstrumentBehavior(kv.ctx.instrument);
  }


    if(kv.ctx.behavior == 'bassline' || kv.ctx.behavior == 'melody'){

      kv.ctx.notes = [];
      var noteCount = 5 + parseInt(Math.random() * 20);

      for(var i =0; i < 16; i++){

        var t = parseInt(Math.random()*32);
        var p = kv.ctx.scale[Math.floor(Math.random()*kv.ctx.scale.length)];
        const r = Math.random();
        if(r > .2)kv.ctx.notes.push({t:i+.5, n:56+p, l:.125});
        kv.ctx.length = 16;

      }

    }else if(kv.ctx.pattern && kv.ctx.behavior && kv.ctx.pattern[kv.ctx.behavior]) {

       kv.ctx.notes = kv.ctx.pattern[kv.ctx.behavior];
       kv.ctx.length = kv.ctx.pattern.length;

    }
}


//todo move this...


/*
const scale  = kv.lookupScale(arg);
if(scale){
  kv.ctx.scale = scale;
  console.log('..found a scale ' + scale);
}

//a pattern an object of timestamps mapping behaviors to a genres... house bass, trap lead, etc
const pattern = kv.lookupPattern(arg);
kv.ctx.pattern = pattern ? pattern : kv.defaultPattern;

*/

kv.syncAudio = function() {


  return;
  //1. see if there are unused/orphaned tones js samplers and remove them
  const activeKeys = Object.keys(kv.samplers);
  for(key of activeKeys){
    var found = false;
     for(track of kv.items){
       if(key == track.instrument?.url)found = true;
     }
     if(!found){
       var old = kv.samplers[key];
       old.part?.dispose();
       old.dispose();
       delete kv.samplers[key];
     }
  }

  kv.audioTracks = [];
  for(const track of kv.items){
    //if no instrument ignore
    if(!track.instrument?.url)continue;
    kv.audioTracks.push(track);
     const key = track.instrument.url;
     var samp = kv.samplers[key];

     //see if any tracks need an tone js sampler...
     if(!samp){
      const urls = {60:kv.fileNameFromUrl(track.instrument.url)};
      const baseUrl = kv.baseFromUrl(track.instrument.url);
      track.isLoading = true;
      samp = new Tone.Sampler({
        urls: urls,
        baseUrl: baseUrl,
        onload: (r) => {
            kv.updateNotes(kv.samplers[key], track);
        }
       }).toDestination();

      kv.samplers[key] = samp;

      // if sampler exists but the note pattern changed update notes
    }else if(!kv.equals(track.notes, samp.notes)){
      console.log('updating notes');
       kv.updateNotes(samp, track);
    }else{
      console.log('not updating... track has ' + track.notes?.length + ' sampler has ' + samp.notes?.length);

    }

  }


  if(kv.items.playing)Tone.Transport.start();
  if(!kv.items.playing)Tone.Transport.stop();
  Tone.Transport.bpm.value = kv.items.bpm;
  Tone.Master.volume.value = -12;

}

kv.updateNotes = function(sampler, track) {

  sampler.release = 1.0
  //if(!kv.equals(track.notes, sampler.notes)){
    const phrase = [];
    sampler.part?.dispose();
    sampler.notes = [...track.notes];
    console.log('ok.. sampler has ' + sampler.notes.length + 'notes');

    for(const n of track.notes){
      phrase.push({key:track.instrument.url, time:kv.b2b(n.t), note:n.n, track:track, length:kv.l2l(n.l || .25)});
    }


  const part = new Tone.Part(function(time, value){

      kv.pulse(value.track);
      const pitch = Tone.Frequency(value.note || 60, "midi").toNote();
      sampler.triggerAttackRelease(pitch, value.length, time, value.velocity);

  }, phrase).start(0);


  part.loop = true;
  part.loopStart = 0;
  part.loopEnd = kv.b2b(track.length);
  sampler.part = part;


}



kv.loadEmojis();


})(kv);
